import classNames from 'classnames';
import React, { useState } from 'react'
import { Container } from 'reactstrap';
import TopBar from '../TopBar';
import SideBar from '../Sidebar/SideBar';
import './Layout.css';

const Layout = ({ children }) => {
    const [sidebarIsOpen, setSidebarOpen] = useState(true);
    const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
    return (
        <div className="cnt wrapper">
            <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
            <Container
                fluid
                className={classNames("content", { "is-open": !sidebarIsOpen })}
            >
                <TopBar toggleSidebar={toggleSidebar} />

                {children}
            </Container>
        </div>
    )
}

export default Layout