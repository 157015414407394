import CardLists from 'Components/CardLists';
import React from 'react'

const Prospection = () => {

    const services = [
        {
            title: "Trouver des prospects via Linkedin Recruiter",
            description: "Trouver comment les trouver via une recherche sur Linkedin Recruiter",
            path: "/prospection/find_prospects_via_linkedin_recruiter"
        },
        
    ]


    return (
        <CardLists items={services} />
    )
}

export default Prospection