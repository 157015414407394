import React, { useState } from 'react'
import GlobalLoading from 'Components/GlobaLoding';
import Display from 'Components/Display';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { GenerateNewWebPages } from 'Api/Marketing';

const CreateNewWebPagesSuggestions = () => {
    const [page_web_url, setPage_web_url] = useState(null);
    const [website_idea, setWebsite_idea] = useState(null);
    const [language, setLanguage] = useState(null);

    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = GlobalLoading()


    const HandleChange = (e, setter) => {
        setter(e.target.value)
    }

    const handleFormValidation = () => {
        let isValid = true;
        let errorMessage = '';
        const regex = /^(https?:\/\/)?([\w\-]+\.)+[\w\-]+(\/[\w\-./?%&=]*)?$/;

        if (!regex.test(page_web_url)) {
            isValid = false;
            errorMessage = 'Lien non valide!'
        }

        if (!page_web_url) {
            isValid = false;
            errorMessage = "champs de l'URL de page web est requis";
        }

        if (!website_idea) {
            isValid = false;
            errorMessage = "Le champs idée est requis ";
        }

        if (!language) {
            isValid = false;
            errorMessage = "champs de langue requis ";
        }

        if (!isValid) {
            setError(errorMessage)
        }

        return isValid;
    };

    const OpenAIAnalysis = async () => {
        setError(null);
        if (handleFormValidation()) {
            setIsLoading(true);
            try {
                const { data } = await GenerateNewWebPages(page_web_url, website_idea, language);
                //console.log(data)
                if (data) {
                    setResult(data);
                }
            } catch (error) {
                setError(error?.response?.data?.message?.message)
            }
            finally {
                setIsLoading(false);
            }
        }
    }

    return (
        <Display
            result={result}
            isLoading={isLoading}
            error={error}
        >
            <Row className="justify-content-center shadow-none p-5 bg-light rounded">
                <Col>
                    <Label>Mettre l' URL d'une page Web</Label>
                    <Input
                        name="url"
                        type='url'
                        placeholder="www.exemple.com"
                        onChange={(e) => HandleChange(e, setPage_web_url)}
                    />
                </Col>
            </Row>
            <Row className="p-5 bg-light">
                <Col sm='6'>
                    <Label>L'idée de site web</Label>
                    <Input
                        type='textarea'
                        row={2}
                        //placeholder="exemple : 600"
                        onChange={(e) => HandleChange(e, setWebsite_idea)}
                    />
                </Col>
                <Col sm='6'>
                    <Label>Choisir la langue du contenu</Label>
                    <Input
                        placeholder="exemple:français"
                        onChange={(e) => HandleChange(e, setLanguage)}
                    />
                </Col>
                
            </Row>
            <Row className='p-3 mb-5 bg-light'>
                    <Button
                        className="w-100"
                        color='primary'
                        onClick={OpenAIAnalysis}
                        disabled={!page_web_url || !website_idea || !language}
                    >
                        Etudier
                    </Button>

                </Row>
        </Display>
    )
}

export default CreateNewWebPagesSuggestions