import React from 'react'
import { Alert } from 'reactstrap'

const AlertMessage = ({message, color}) => {
  return (
  
    <Alert className='text-center' color={color}>{message}</Alert>
  )
}

export default AlertMessage