import React from 'react'
import { Button, Input, Label, Row } from 'reactstrap'

const InputEntry = ({value,type, label, error, HandleChange, handleClick,labelBtn,placeholder,rows }) => {
    return (
        <>
            <Label>{label}</Label>
            <Row className="justify-content-center shadow-none p-5 mb-5 bg-light rounded">
                <Input
                    className={`w-50 ${error && "border border-danger"}`}
                    type={type}
                    rows={rows || null}
                    placeholder={placeholder || ''}
                    onChange={HandleChange}
                />
                <Button
                    color='primary'
                    className='ml-2'
                    onClick={handleClick}
                    disabled={!value}
                >
                    {labelBtn}
                </Button>
            </Row>
        </>

    )
}

export default InputEntry