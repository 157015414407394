import React, { useState } from 'react'
import Display from 'Components/Display';
import GlobalLoading from 'Components/GlobaLoding';
import InputEntry from 'Components/Entries/InputEntry';
import { MakeProjectWellProfitable } from 'Api/Strategy';

const MakeProjectProfitable = () => {
    const [idea, setIdea] = useState(null);
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = GlobalLoading()


    const HandleChange = (e) => {
        setIdea(e.target.value)
    }

    const StartAnalysis = async () => {
        setError(null);
            setIsLoading(true);
            try {
                if (idea) {
                    const { data } = await MakeProjectWellProfitable(idea);
                    //console.log(data)
                    if (data) {
                        setResult(data);
                    }
                }
            } catch (error) {
                setError(error?.response?.data?.message?.message)
            }
            finally {
                setIsLoading(false);
            }
    }

    return (
        <Display
            result={result}
            isLoading={isLoading}
            error={error}
        >
            <InputEntry
                type="textarea"
                value={idea}
                labelBtn={"Etudier"}
                label={"Mettre votre idée avec ses valeurs ajoutées"}
                error={error}
                handleClick={StartAnalysis}
                HandleChange={HandleChange}
            />
        </Display>
    )
}

export default MakeProjectProfitable