import React from 'react'
import { Container, Row } from 'reactstrap'

const Welcome = () => {
  return (
    <Container >
      <Row className='justify-content-center'>
      <h2>Welcome</h2>
      </Row>
    </Container>
  )
}

export default Welcome