import CardLists from 'Components/CardLists';
import React from 'react'

const Marketing = () => {

    const services = [
        {
            title: "Etude design & UX",
            description: "Etudier le design et la user experience (UX) d'une page web",
            path: "/marketing/design&ux_study"
        },
        {
            title: "Créer une publication Linkedin",
            description : "Créer une publication Linkedin à travers une page web",
            path: "/marketing/create_linkedin_post"
        },
        {
            title: "Améliorer le contenu Web",
            description : "Changer le contenu d'une page web à partir d'autre web page d'inspiration",
            path: "/marketing/change_web_content_from_inspiration_one"
        },
        {
            title: "Créer une publicité Linkedin",
            description : "Changer une publicité linkedin selon votre site web",
            path: "/marketing/create_linkedin_advertisement"
        },
        {
            title: "Générer de nouveaux pages web",
            description : "Générer de nouveaux pages web pour votre site web",
            path: "/marketing/page_web_suggestions"
        },
        {
            title: "Créer un post Linkedin à travers les meilleures vidéos du moment sur Youtube",
            description : "",
            path: "/marketing/create_linkedin_post_from_popular_youtubeTexts"
        },
        
    ]


    return (
        <CardLists items={services} />
    )
}

export default Marketing