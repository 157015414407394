import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons";
import {
  Navbar,
  Button,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import { useHistory } from "react-router-dom";

const TopBar = ({ toggleSidebar }) => {
  const [topbarIsOpen, setTopbarOpen] = useState(true);
  const toggleTopbar = () => setTopbarOpen(!topbarIsOpen);

  const history = useHistory();

  const LogOut = () => {
    localStorage.clear()
    history.push('/')
  }

  return (
    <Navbar
      color="light"
      light
      className="navbar shadow-sm p-3 mb-5 bg-white rounded"
      expand="md"
    >
      <Button color="info" onClick={toggleSidebar}>
        <FontAwesomeIcon icon={faAlignLeft} />
      </Button>
      <NavbarToggler onClick={toggleTopbar} />
      <Collapse isOpen={topbarIsOpen} navbar>
        <nav className="ml-auto" navbar style={{cursor: 'pointer'}}>
          <i class="fa-solid fa-right-from-bracket"></i>
          <span onClick={LogOut}  className="ml-2 cursor-pointer">Se déconnecter</span>
        </nav>

      </Collapse>
    </Navbar>
  );
};

export default TopBar;
