import CardLists from 'Components/CardLists';
import React from 'react'

const General = () => {

    const services = [
        {
            title: "Capture d'écran d'une page web",
            description: "Capturer la page web complète",
            path: "/general/web_page_screenshot"
        },
        {
            title: "Speech to text",
            description: "Transcription video ou audio en texte",
            path: "/general/speech_to_text"
        },
    ]


    return (
        <CardLists items={services} />
    )
}

export default General