import Login from 'Components/Login'
import HomepageLayout from 'layouts/HomepageLayout'
import React from 'react'

const LoginView = () => {
  return (
    <>
    <HomepageLayout />
    <Login />
    </>
  )
}

export default LoginView