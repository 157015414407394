import axios from "axios";
import config from "../config";


const instance = axios.create({
  baseURL: config.WS_BASE_URL2,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  //config.headers.ContentType = "application/json";
  return config;
});

export default instance