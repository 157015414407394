import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faHome
} from "@fortawesome/free-solid-svg-icons";
import { NavItem, NavLink, Nav, NavbarBrand } from "reactstrap";
import classNames from "classnames";
import { Link, NavLink as NavLinkRRD } from "react-router-dom";
import logo from '../../assets/img/logo-finboxio-300-150-px-2-150x100-removebg-preview.png'


const SideBar = ({ isOpen, toggle }) => {

  /* const LogOut = () => {
    localStorage.clear()
  } */


  return (
    <div className={classNames("sidebar", "navbar-light","fixed", { "is-open": isOpen })}>
      <div className="sidebar-header">

        <NavbarBrand
          className="pt-0 pl-5"
          to="/"
          tag={Link}
        >
          <img
            src={logo}
            alt={"..."}
            className=""
            style={{
              objectFit: "cover",
            }}
          />
        </NavbarBrand>

        <span color="info" onClick={toggle} style={{ color: "black", float: 'right' }}>
          &times;
        </span>

      </div>
      <div className="side-menu">
        <Nav vertical className="list-unstyled pb-3 pl-2" navbar >
          {/*         <SubMenu title="Home" icon={faHome} items={submenus[0]} />
 */}        <NavItem>
            <NavLink
              tag={NavLinkRRD}
              to={"/home"}
              exact
              //activeClassName="active"
              className={"/home"}
               >
              <FontAwesomeIcon icon={faHome} className="mr-2" />
              Home
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              tag={NavLinkRRD}
              to={"/marketing"}
              exact
              activeClassName="active" >
              <FontAwesomeIcon icon={faBriefcase}  className="mr-2" />
              Marketing
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              tag={NavLinkRRD}
              to={"/strategy"}
              exact
              activeClassName="active" >
              <FontAwesomeIcon icon={faBriefcase}  className="mr-2" />
              Stratégie
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              tag={NavLinkRRD}
              to={"/prospection"}
              exact
              activeClassName="active" >
              <FontAwesomeIcon icon={faBriefcase}  className="mr-2" />
              Prospection
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              tag={NavLinkRRD}
              to={"/general"}
              exact
              activeClassName="active" >
              <FontAwesomeIcon icon={faBriefcase}  className="mr-2" />
              Général
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              tag={NavLinkRRD}
              to={"/development"}
              exact
              activeClassName="active" >
              <FontAwesomeIcon icon={faBriefcase}  className="mr-2" />
              Développemnt
            </NavLink>
          </NavItem>

        </Nav>
      </div>
    </div>
  )
};


export default SideBar;
