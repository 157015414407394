import React from "react";
import HomepageLayout from "../layouts/HomepageLayout";
import { Card, CardBody, Col } from "reactstrap";
import Register from "../Components/Register";
 

const RegisterView = () => {



    return (
        <>
            <HomepageLayout />

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 150,
                }}

            >
                <Col lg="8" md="7">
                    <Card className="shadow border-0">
                        <CardBody className="px-lg-5 py-lg-5">
                            <Register /> 
                        </CardBody>
                    </Card>
                </Col>
            </div>


        </>
    );
};

export default RegisterView;
