import React from 'react'
import Layout from 'Components/Layout/Layout';
import Marketing from 'Components/Services/Marketing';


const MarketingView = () => (
        <Layout>
            <Marketing />
        </Layout>
)


export default MarketingView