import React from 'react'
import Layout from '../Components/Layout/Layout';
import Welcome from 'Components/Welcome';


const WelcomeView = () => (
        <Layout><Welcome/></Layout>
)


export default WelcomeView