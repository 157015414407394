import CardLists from 'Components/CardLists';
import React from 'react'

const Development = () => {

    const services = [
        {
            title: "Nettoyer le script google colab",
            description: "Corriger le script Google Colab afin d'avoir un code python qu'on peut installer dans un docker python (fast API)",
            path: "/development/get_deployable_fastAPI_Script_from_googleColab"
        },
        
    ]


    return (
        <CardLists items={services} />
    )
}

export default Development