import React, { useState } from 'react'
import Display from 'Components/Display';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { ChangeWebContentFromInspirationWebPage } from 'Api/Marketing';
import GlobalLoading from 'Components/GlobaLoding';

const ChangeContentFromInspirationPageView = () => {
    const [page_web_inspiration_url, setPage_web_inspiration_url] = useState(null);
    const [our_web_page_to_modify_url, setOur_web_page_to_modify_url] = useState(null);
    const [our_website_idea, setOur_website_idea] = useState(null);
    const [language, setLanguage] = useState(null);

    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = GlobalLoading()


    const HandleChange = (e, setter) => {
        setter(e.target.value)
    }


    /* const HandleWebURLValidation = () => {
        const regex = /^(https?:\/\/)?([\w\-]+\.)+[\w\-]+(\/[\w\-./?%&=]*)?$/;
        if (!regex.test(page_web_inspiration_url) && !regex.test(our_web_page_to_modify_url)) {
            setError('Lien non valide!')
        }
        //console.log('test', regex.test(url))
        return (regex.test(page_web_inspiration_url) && regex.test(our_web_page_to_modify_url));
    } */

    const handleFormValidation = () => {
        let isValid = true;
        let errorMessage = '';
        const regex = /^(https?:\/\/)?([\w\-]+\.)+[\w\-]+(\/[\w\-./?%&=]*)?$/;

        if (!regex.test(page_web_inspiration_url) || !regex.test(our_web_page_to_modify_url)) {
            isValid = false;
            errorMessage = 'Lien non valide!'
        }

        if (!page_web_inspiration_url) {
          isValid = false;
          errorMessage ="champs de l'URL de page web d'inspiration est requis";
        }
        if (!our_web_page_to_modify_url) {
          isValid = false;
          errorMessage ="champs de l'URL de page web à modifier est requis";
        }
        if (!our_website_idea) {
          isValid = false;
          errorMessage ="champs d'idée requis ";
        }
        
        if (!language) {
          isValid = false;
          errorMessage ="champs de langue requis ";
        }

        if(!isValid){
            setError(errorMessage)
        }
        
        return isValid;
      };

    const OpenAIAnalysis = async () => {
        setError(null);
        if (handleFormValidation()) {
            setIsLoading(true);
            try {
                const { data } = await ChangeWebContentFromInspirationWebPage(page_web_inspiration_url, our_web_page_to_modify_url, our_website_idea, language);
                //console.log(data)
                if (data) {
                    setResult(data);
                }
            } catch (error) {
                setError(error?.response?.data?.message?.message)
            }
            finally {
                setIsLoading(false);
            }
        }
    }

    return (
        <Display
            result={result}
            isLoading={isLoading}
            error={error}
        >
                <Row className="justify-content-center shadow-none p-5 bg-light rounded">
                    <Col sm='6'>
                        <Label>Mettre l' URL d'une page Web d'inspiration</Label>
                        <Input
                            name="url"
                            //className={`${error && "border border-danger"}`}
                            type='url'
                            placeholder="www.exemple.com"
                            onChange={(e) => HandleChange(e, setPage_web_inspiration_url)}
                        />
                    </Col>
                    <Col sm='6'>
                        <Label>Mettre l' URL d'une page Web à modifier</Label>
                        <Input
                            //className={`${error && "border border-danger"}`}
                            type='url'
                            placeholder="www.exemple.com"
                            onChange={(e) => HandleChange(e, setOur_web_page_to_modify_url)}
                        />
                    </Col>
                </Row>
                <Row className="p-5 bg-light">
                    <Col sm='6'>
                        <Label>Mettre l'idée de votre site web</Label>
                        <Input
                            //className={`${error && "border border-danger"}`}
                            type='textarea'
                            row={2}
                            //placeholder="www.exemple.com"
                            onChange={(e) => HandleChange(e, setOur_website_idea)}
                        />
                    </Col>
                    <Col sm='6'>
                        <Label>Choisir la langue</Label>
                        <Input
                            //className={`${error && "border border-danger"}`}
                            placeholder="exemple:français"
                            onChange={(e) => HandleChange(e, setLanguage)}
                        />
                    </Col>
                </Row>
                <Row className='p-2 mb-5 bg-light'>
                    <Button
                        className="w-100"
                        color='primary'
                        onClick={OpenAIAnalysis}
                        disabled={!page_web_inspiration_url || !our_web_page_to_modify_url || !our_website_idea || !language}
                    >
                        Etudier
                    </Button>
                </Row>
        </Display>
    )
}

export default ChangeContentFromInspirationPageView