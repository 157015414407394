import React, { useState } from 'react'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { useLocation, useHistory } from "react-router-dom";
import { SignIn } from 'Api/user';

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();

  const TryLogin = async () => {
    setError(null);
    try {
      const { data } = await SignIn({email, password});
    if (data.success) {
      localStorage.setItem("token", data.token);
      localStorage.setItem("user", JSON.stringify(data.user));
      history.push(`/home`);
      
    } 
    } catch (error) {
      setError(error?.response?.data?.message);
    }
    setPassword("");
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 150,
        }}

      >
        <Row className='mt-2'>
          <Col>
            <Card className="shadow border-0">
              <CardHeader className="bg-transparent pb-5">
                <div className="text-muted text-center mt-2 mb-3">
                  <h4>S'identifier</h4>
                </div>

              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">

                <Form role="form">
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        autoComplete="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Mot de passe"
                        type="password"
                        autoComplete="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  
                  {error ? (
                    <div className="text-muted font-italic">
                      <small>
                        error:{" "}
                        <span style={{color: "red"}} className="text-red font-weight-700">{error}</span>
                      </small>
                    </div>
                  ) : null}
                  <div className="text-center">
                    <Button
                      className="my-4"
                      style={{

                        width: "100%",
                      }}
                      color="primary"
                      type="button"
                      onClick={TryLogin}
                    >
                      Se connecter
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              {/* <Col xs="6">
            <a
              className="text-light"
              onClick={() => props.history.push("/auth/reset-password")}
            >
              <small>Forgot password?</small>
            </a>
          </Col> */}
              <Col className="text-left" xs="6">
                <a
                  className="text-secondary"
                  onClick={() => history.push("/signup")}
                >
                  <small>créer un nouveau compte</small>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

    </>
  )
}

export default Login