import React from 'react'
import Layout from 'Components/Layout/Layout';
import Development from 'Components/Services/Development';


const DevelopmentView = () => (
        <Layout>
           <Development />
        </Layout>
)


export default DevelopmentView