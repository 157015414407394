import { BrowserRouter, Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import RegisterView from "./Views.js/RegisterView";
import HomePageView from "Views.js/HomePageView";
import LoginView from "Views.js/LoginView";
import WelcomeView from "Views.js/Welcome";
import PrivateRoute from "PrivateRoute/PrivateRoute";
import MarketingView from "Views.js/Marketing/index";
import DesignUXstudyView from "Views.js/Marketing/DesignUXstudyView";
import CreateLinkedinPostView from "Views.js/Marketing/CreateLinkedinPostView";
import CreateLinkedinAdvertisement from "Views.js/Marketing/CreateLinkedinAdvertisement";
import CreateNewWebPagesSuggestions from "Views.js/Marketing/CreateNewWebPagesSuggestions";
import ChangeContentFromInspirationPageView from "Views.js/Marketing/ChangeWebContentFromInspirationPage";
import StrategyView from "Views.js/Strategy";
import MVP_Analysis from "Views.js/Strategy/MVP_Analysis";
import BestBuyersAnalysis from "Views.js/Strategy/BestBuyersAnalysis";
import LandingPageProspectsAnalysis from "Views.js/Strategy/LandingPageProspectsAnalysis";
import GeneralView from "Views.js/General";
import CreateWebPageFullCapture from "Views.js/General/CreateWebPageFullCapture";
import SpeechToText from "Views.js/General/SpeechToText";
import DevelopmentView from "Views.js/Development";
import GenerateScriptFromGoogleCollabScript from "Views.js/Development/GenerateScriptFromGoogleCollabScript";
import CompetitorsStudy from "Views.js/Strategy/CompetitorsStudy";
import GenerateWebSiteName from "Views.js/Strategy/GenerateWebSiteName";
import MakeProjectProfitable from "Views.js/Strategy/MakeProjectProfitable";
import AddedValuesProjectStudy from "Views.js/Strategy/AddedValuesProjectStudy";
import ProspectingChannelForSegments from "Views.js/Strategy/ProspectingChannelForSegments";
import FindProspectsViaLinkedinRecruiter from "Views.js/Prospection/FindProspectsViaLinkedinRecruiter";
import ProspectionView from "Views.js/Prospection";
import DistributionChannelsStudy from "Views.js/Strategy/DistributionChannelsStudy";
import StudyStepsForStartUp from "Views.js/Strategy/StudyStepsForStartUp";
import FindUsersManners from "Views.js/Strategy/FindUsersManners";
import StartupCostStudy from "Views.js/Strategy/StartupCostStudy";
import ProjectCoreStudy from "Views.js/Strategy/ProjectCoreStudy";
import GoNoToStartProjectDevelopment from "Views.js/Strategy/GoNoToStartProjectDevelopment";
import UxProjectCoreStudy from "Views.js/Strategy/UxProjectCoreStudy";
import TechnicalExecutionPlanForTestingNewIdea from "Views.js/Strategy/TechnicalExecutionPlanForTestingNewIdea";
import GlobalExecutionPlanForTestingNewIdea from "Views.js/Strategy/GlobalExecutionPlanForTestingNewIdea";
import CreateLinkedinPostFromPopularYoutubeTexts from "Views.js/Marketing/CreateLinkedinPostFromPopularYoutubeTexts";


function App() {
  
  return (
    <BrowserRouter>
      <Switch>
    
        <Route exact  path='/' component={HomePageView} />
        <PrivateRoute exact  path='/home' component={WelcomeView} />
        <PrivateRoute exact  path='/marketing' component={MarketingView} />
        <PrivateRoute exact  path='/marketing/design&ux_study' component={DesignUXstudyView} />
        <PrivateRoute exact  path='/marketing/create_linkedin_post' component={CreateLinkedinPostView} />
        <PrivateRoute exact  path='/marketing/change_web_content_from_inspiration_one' component={ChangeContentFromInspirationPageView} />
        <PrivateRoute exact  path='/marketing/create_linkedin_advertisement' component={CreateLinkedinAdvertisement} />
        <PrivateRoute exact  path='/marketing/page_web_suggestions' component={CreateNewWebPagesSuggestions} />
        <PrivateRoute exact  path='/marketing/create_linkedin_post_from_popular_youtubeTexts' component={CreateLinkedinPostFromPopularYoutubeTexts} />

        <PrivateRoute exact  path='/strategy' component={StrategyView} />
        <PrivateRoute exact  path='/strategy/MVP_analysis' component={MVP_Analysis} />
        <PrivateRoute exact  path='/strategy/Best_Buyers_analysis' component={BestBuyersAnalysis} />
        <PrivateRoute exact  path='/strategy/landingPage_prospects_analysis' component={LandingPageProspectsAnalysis} />
        <PrivateRoute exact  path='/strategy/Competitors_Study' component={CompetitorsStudy} />
        <PrivateRoute exact  path='/strategy/generate_webSite_name' component={GenerateWebSiteName} />
        <PrivateRoute exact  path='/strategy/make_project_profitable' component={MakeProjectProfitable} />
        <PrivateRoute exact  path='/strategy/addedValues_study' component={AddedValuesProjectStudy} />
        <PrivateRoute exact  path='/strategy/prospecting_channel_for_segments' component={ProspectingChannelForSegments} />
        <PrivateRoute exact  path='/strategy/distribution_channels_study' component={DistributionChannelsStudy} />
        <PrivateRoute exact  path='/strategy/study_steps_startup' component={StudyStepsForStartUp} />
        <PrivateRoute exact  path='/strategy/find_users_manners' component={FindUsersManners} />
        <PrivateRoute exact  path='/strategy/startup_costs_study' component={StartupCostStudy} />
        <PrivateRoute exact  path='/strategy/project_core_study' component={ProjectCoreStudy} />
        <PrivateRoute exact  path='/strategy/goNo_to_start_project_development' component={GoNoToStartProjectDevelopment} />
        <PrivateRoute exact  path='/strategy/ux_project_core_study' component={UxProjectCoreStudy} />
        <PrivateRoute exact  path='/strategy/technical_execution_plan_for_testing_new_idea' component={TechnicalExecutionPlanForTestingNewIdea} />
        <PrivateRoute exact  path='/strategy/global_execution_plan_for_testing_new_idea' component={GlobalExecutionPlanForTestingNewIdea} />
        
        <PrivateRoute exact  path='/prospection' component={ProspectionView} />
        <PrivateRoute exact  path='/prospection/find_prospects_via_linkedin_recruiter' component={FindProspectsViaLinkedinRecruiter} />
        
        <PrivateRoute exact  path='/general' component={GeneralView} />
        <PrivateRoute exact  path='/general/web_page_screenshot' component={CreateWebPageFullCapture} />
        <PrivateRoute exact  path='/general/speech_to_text' component={SpeechToText} />

        <PrivateRoute exact  path='/development' component={DevelopmentView} />
        <PrivateRoute exact  path='/development/get_deployable_fastAPI_Script_from_googleColab' component={GenerateScriptFromGoogleCollabScript} />
        
        <Route exact  path='/Signup' component={RegisterView} />
        <Route exact  path='/signin' component={LoginView} />


      </Switch>
    </BrowserRouter>
  );
}

export default App;
