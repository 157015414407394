import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Button, Card, CardText, CardTitle, Container } from 'reactstrap'

const CardLists = ({items}) => {
    const history = useHistory();


    const HandleNavigate = (path) => {
        history.push(path)
    }

    return (
        <Container className='d-flex flex-wrap justify-content-center'>
                {items?.map((service) => <Card
                    key={service.title}
                    body
                    className="text-center m-2"
                    style={{
                        width: '18rem',
                        flex: '0 0 18rem'
                    }}
                >
                    <CardTitle tag="h5">
                        {service.title}
                    </CardTitle>
                    <CardText>{service.description}
                    </CardText>
                    <Button
                        color="primary"
                        onClick={()=>HandleNavigate(service?.path)}
                    >
                        Accéder
                    </Button>
                </Card>)}
        </Container>
    )
}

export default CardLists