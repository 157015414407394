import CardLists from 'Components/CardLists';
import React from 'react'

const Strategy = () => {

    const services = [
        {
            title: "Etude MVP",
            description: "Etudier le MVP d'un produit/service",
            path: "/strategy/MVP_analysis"
        },
        {
            title: "Etude meilleurs cibles",
            description: "Trouve les meilleurs cibles qui peuvent acheter votre service",
            path: "/strategy/Best_Buyers_analysis"
        },
        {
            title: "Etude Prospects à travers une page web",
            description: "Trouver les meilleurs prospects à cibler à votre landing page",
            path: "/strategy/landingPage_prospects_analysis"
        },
        {
            title: "Recherche des concurrents",
            description: "Chercher s'il y a des concurrents pour votre idée",
            path: "/strategy/Competitors_Study"
        },
        {
            title: "Trouver un nom d'un site web",
            description: "Trouver un nom d'un site web selon votre idée",
            path: "/strategy/generate_webSite_name"
        },
        {
            title: "Rendre le projet profitable",
            description: "Chercher comment rendre le projet profitable",
            path: "/strategy/make_project_profitable"
        },
        {
            title: "Détailler les valeurs ajoutées du projet",
            description: "Brainstorming et étude des valeurs ajoutées",
            path: "/strategy/addedValues_study"
        },
        {
            title: "Canal de prospection pour les segments",
            description: "Canal de prospection pour les segments pour votre idée",
            path: "/strategy/prospecting_channel_for_segments"
        },
        {
            title: "startup Canaux de distribution",
            description: "Etudier les Canaux de distribution possibles",
            path: "/strategy/distribution_channels_study"
        },
        {
            title: "Startup: Etapes à réaliser",
            description: "Etudier les étapes à réaliser",
            path: "/strategy/study_steps_startup"
        },
        {
            title: "Startup: Actuellement comment les utilisateurs travaillent",
            description: "",
            path: "/strategy/find_users_manners"
        },
        {
            title: "Startup: Etude des couts",
            description: "Etude les couts de votre produit/service",
            path: "/strategy/startup_costs_study"
        },
        {
            title: "The Core is the core of the project",
            description: "Etudier le meilleur core du business",
            path: "/strategy/project_core_study"
        },
        {
            title: "Go/No pour entamer le développement du projet",
            description: "Lancier ou non le développement de votre idée",
            path: "/strategy/goNo_to_start_project_development"
        },
        {
            title: "The UX for The Core is the core of the project",
            description: "Définir les termes UX pour votre idée",
            path: "/strategy/ux_project_core_study"
        },
        {
            title: "Plan d'exécution technique idéale",
            description: "Plan d'exécution technique idéale qu'il faut suivre afin de tester ce nouveau service/produit",
            path: "/strategy/technical_execution_plan_for_testing_new_idea"
        },
        {
            title: "Plan d'exécution globale idéale",
            description: "Plan d'exécution globale idéale qu'il faut suivre afin de tester ce nouveau service/produit",
            path: "/strategy/global_execution_plan_for_testing_new_idea"
        }
    ]


    return (
        <CardLists items={services} />
    )
}

export default Strategy