import React from 'react'
import Layout from 'Components/Layout/Layout';
import Prospection from 'Components/Services/Prospection';


const ProspectionView = () => (
        <Layout>
            <Prospection />
        </Layout>
)


export default ProspectionView