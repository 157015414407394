import React from 'react'
import Layout from 'Components/Layout/Layout';
import General from 'Components/Services/General';


const GeneralView = () => (
        <Layout>
           <General />
        </Layout>
)


export default GeneralView