import React, { useState } from 'react'
import Display from 'Components/Display';
import GlobalLoading from 'Components/GlobaLoding';
import InputEntry from 'Components/Entries/InputEntry';
import { GetMVP_Analysis } from 'Api/Strategy';

const MVP_Analysis = () => {
    const [idea, setIdea] = useState(null);
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = GlobalLoading()


    const HandleChange = (e) => {
        setIdea(e.target.value)
    }

    const StartMvpAnalysis = async () => {
        setError(null);
            setIsLoading(true);
            try {
                if (idea) {
                    const { data } = await GetMVP_Analysis(idea);
                    //console.log(data)
                    if (data) {
                        setResult(data);
                    }
                }
            } catch (error) {
                setError(error?.response?.data?.message?.message)
            }
            finally {
                setIsLoading(false);
            }
    }

    return (
        <Display
            result={result}
            isLoading={isLoading}
            error={error}
        >
            <InputEntry
                type="textarea"
                value={idea}
                labelBtn={"Etudier"}
                label={"Mettre votre idée"}
                error={error}
                handleClick={StartMvpAnalysis}
                HandleChange={HandleChange}
            />
        </Display>
    )
}

export default MVP_Analysis