import React, { useEffect, useRef, useState } from "react";

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup
} from "reactstrap";
import { PencilAltIcon } from "@heroicons/react/outline";
import { options_Pays } from "options";
import Select from 'react-select';
import { SignUp } from "Api/user";
import AlertMessage from "./AlertMessage";
import {useHistory} from 'react-router-dom'



const Register = (props) => {
  const history= useHistory();
  
  const [user, setUser]= useState({});
  const [location, setLocation] = useState("");
  const [confirmPassword, setConfirmPassword] = useState('');
  const intervalRef = useRef(null);

  const HandleChange = (e)=>{
    setUser({...user, [e.target.name] : e.target.value})
  }


  const [error, setError] = useState("");
  const [toastMessage, setToastMessage]= useState('');
  



  const handleValidation = () => {
    let isValid = true;

    if (!user.firstName) {
      isValid = false;
      setError("impossible de procéder avec un prénom vide ");
      return isValid;
    }

    if (!user.lastName) {
      isValid = false;
      setError("impossible de procéder avec un nom vide ");
      return isValid;

    };

    if (!user.email) {

      if (typeof user?.email !== "undefined") {
        let lastAtPos = user.email.lastIndexOf("@");
        let lastDotPos = user.email.lastIndexOf(".");

        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            user.email.indexOf("@@") == -1 &&
            lastDotPos > 2 &&
            user.email.length - lastDotPos > 2
          )
        ) {
          isValid = false;
          setError("L'Email  n'est pas valide");
          return isValid;
        }
      }

      console.log(error);

    }

    if (!location) {
      isValid = false;
      setError("impossible de procéder avec pays vide ");
      return isValid;
    }





    if (!user.password) {
      isValid = false;
      setError("impossible de procéder avec un mot de passe vide ");
      return isValid;
    }
    if (!confirmPassword) {
      isValid = false;
      setError("impossible de procéder sans confirmer le mot de passe ");
      return isValid;
    }

    if (user.password !== confirmPassword) {
      isValid = false;
      setError("Merci de de bien confirmer le mot de passe: Les mots de passe ne correspondent pas !");
      // setError("Les mots de passe ne correspondent pas");
      return isValid;
    }

    return isValid;

  }

  const RegisterUser = async () => {
    setError(null)
    try {
      if (handleValidation()) {
        const {data} = await SignUp({...user,country : location});
        if (data.success) {
          setToastMessage(
            data.msg
          );
          intervalRef.current = setInterval(()=>{
            history.push('/signin')
          }, 1000);
          return;
        }

        setUser({})
        setConfirmPassword("");
      } 
      
    } catch (error) {
      console.log(error)
      setError(error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data?.msg );

    }
  };

  useEffect(()=>{
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }
  },[])




  return (
    <>
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: "fixed",
          minHeight: "100px",
          width: "35%",
          right: 10,
          bottom: 80,
          zIndex: 50,
        }}
      >
      </div>
      <Form role="form">
        <FormGroup>
          <InputGroup className="input-group-alternative mb-3">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <PencilAltIcon />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Prénom"
              type="text"
              name="firstName"
              value={user.firstName}
              required
              onChange={HandleChange}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <InputGroup className="input-group-alternative mb-3">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <PencilAltIcon />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Nom"
              type="text"
              name="lastName"
              value={user.lastName}
              required
              onChange={HandleChange}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <InputGroup className="input-group-alternative mb-3">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <PencilAltIcon />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Email"
              type="email"
              autoComplete="new-email"
              name="email"
              value={user.email}
              required
              onChange={HandleChange}
            />
          </InputGroup>
        </FormGroup>



        <FormGroup className="w-full">
          {/* <InputGroup className="input-group-alternative mb-3">
            <InputGroupAddon addonType="prepend">
            </InputGroupAddon> */}
          <label className="px-lg-3">Pays</label>
          <Select
            className="w-full"
            options={options_Pays}

            onChange={(e) => {
              setLocation(e.value);
            }}
          />
          {/*           </InputGroup>
 */}        </FormGroup>


        <FormGroup>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <PencilAltIcon />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Mot de passe"
              type="password"
              autoComplete="new-password"
              name="password"
              value={user.password}
              required
              onChange={HandleChange}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <PencilAltIcon />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Confirmer mot de passe"
              type="password"
              autoComplete="new-password"
              value={confirmPassword}
              required
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </InputGroup>
        </FormGroup>
        {error ? (
          <div className="text-muted font-italic">
            <small>
              error:{" "}
              <span style={{color: 'red'}} className="text-red font-weight-700">{error}</span>
            </small>
          </div>
        ) : null}

        {
          toastMessage && <AlertMessage message={toastMessage} color='success' />
        }


        <div className="text-center">
          <Button
            style={{

              width: "100%",
            }}
            onClick={RegisterUser}
            className="mt-4"
            color="primary"
            type="button"
          >
            Créer mon compte
          </Button>
        </div>
      </Form>
    </>
  );
};

export default Register;
