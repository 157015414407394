import React from 'react'
import Layout from 'Components/Layout/Layout';
import Strategy from 'Components/Services/Strategy';


const StrategyView = () => (
        <Layout>
            <Strategy />
        </Layout>
)


export default StrategyView