import instance from './index';

export const GetMVP_Analysis = async(idea) => await instance.post(`strategy/MVP_analysis/`, {idea});
export const GetBestBuyersAnalysis = async(idea) => await instance.post(`strategy/Best_Buyers_analysis`, {idea});
export const GetLandingPageProspectsAnalysis = async(url) => await instance.get(`strategy/landingPage_prospects_analysis/${url}`);
export const CompetitorStudy = async(idea) => await instance.post(`strategy/Competitors_Study`, {idea});
export const CreateWebSiteName = async(idea) => await instance.post(`strategy/generate_webSite_name`, {idea});
export const MakeProjectWellProfitable = async(idea) => await instance.post(`strategy/make_project_profitable`, {idea});
export const addedValueStudy = async(idea) => await instance.post(`strategy/addedValues_study`, {idea});
export const ProspectingChannelsForSegments = async(idea) => await instance.post(`strategy/prospecting_channel_for_segments`, {idea});
export const FindProspectViaLinkedinRecruiter = async(segments) => await instance.post(`strategy/find_prospects_via_linkedin_recruiter`, {segments});
export const DistributionChannels_Study = async(idea) => await instance.post(`strategy/distribution_channels_study`, {idea});
export const StudySteps_StartUp = async(idea) => await instance.post(`strategy/study_steps_startup`, {idea});
export const findUsers_Manners = async(idea) => await instance.post(`strategy/find_users_manners`, {idea});
export const StartupCostsStudy = async(idea) => await instance.post(`strategy/startup_costs_study`, {idea});
export const ProjectCore_study = async(idea) => await instance.post(`strategy/project_core_study`, {idea});
export const GoNoForProjectDevelopment = async(data) => await instance.post(`strategy/goNo_to_start_project_development`, data);
export const UxProjectCore_study = async(data) => await instance.post(`strategy/ux_project_core_study`, data);
export const TechnicalExecutionPlan_TestingNewIdea = async(idea) => await instance.post(`strategy/technical_execution_plan_for_testing_new_idea`, {idea});
export const GlobalExecutionPlan_TestingNewIdea = async(idea) => await instance.post(`strategy/global_execution_plan_for_testing_new_idea`, {idea});



