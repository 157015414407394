import HomepageLayout from 'layouts/HomepageLayout'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';

const HomePageView = () => {
  const history = useHistory()
  const HandlePageRedirection = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      history.push("/home")

    }
  }

  useEffect(() => {
    HandlePageRedirection()
  }, [])
  return (
    <>
      <HomepageLayout>

      </HomepageLayout>

    </>
  )
}

export default HomePageView